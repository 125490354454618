import React, { useEffect, useState } from "react";
import debounce from "lodash/debounce";
import { graphql } from "gatsby";
import { useLocation } from "@reach/router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import GraphQLErrorList, {
  GraphQLError
} from "../components/graphql-error-list";
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import SearchBar from "../components/Search/SearchBar";

import { useFlexSearch } from "react-use-flexsearch";
import {
  unflattenEpisodeResults,
  unflattenPostResults,
  unflattenProductResults
} from "../helpers/content";
import { TransistorEpisodeInterface } from "../interfaces/podcasts";
import SearchResults from "../components/Search/SearchResults";
import useIsMounted from "ismounted";
import { trackSearchQuery } from "../helpers/trackingEvents";
import { BlogPostInterface, NodesInterface } from "../interfaces/blogPost";
import useLocalStorage from "../hooks/useLocalStorage";
import { EmotionStyles } from "../interfaces/emotion";
import { variables } from "../styles/variables";
import Page from "../components/Page";
import { SiteInterface } from ".";
import { ProductInterface } from "../interfaces/product";

export interface EpisodeResult {
  title: string;
  published_at: string;
  summary: string;
  id: string;
}

export type PostResult = Partial<BlogPostInterface>;
export type ProductResult = Partial<ProductInterface>;

interface LocalSearchDataInterface {
  index: Array<string>;
  store: Array<string>;
}
interface SearchPageData {
  sanitySiteSettings: SiteInterface;
  localSearchEpisodes: LocalSearchDataInterface;
  localSearchPosts: LocalSearchDataInterface;
  localSearchProducts: LocalSearchDataInterface;
  allTransistorEpisode: NodesInterface<TransistorEpisodeInterface>;
  allSanityPost: NodesInterface<BlogPostInterface>;
  allSanityProduct: NodesInterface<ProductInterface>;
}

const styles: EmotionStyles = {
  closeIconContainer: {
    display: "flex",
    justifyContent: "flex-end",
    cursor: "pointer",
    marginBottom: variables.spacing.medium
  }
};

export const pageQuery = graphql`
  query SearchPageQuery {
    sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    localSearchEpisodes {
      index
      store
    }
    localSearchPosts {
      index
      store
    }
    localSearchProducts {
      index
      store
    }
    allTransistorEpisode {
      nodes {
        id
        image {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        ...TransistorEpisodeAttributes
      }
    }
    allSanityPost {
      nodes {
        ...BlogPostFragment
      }
    }
    allSanityProduct {
      nodes {
        ...ProductFragment
      }
    }
  }
`;

const SearchPage: React.FC<{
  data: SearchPageData;
  errors: Array<GraphQLError>;
}> = ({ data, errors }) => {
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }
  const mounted = useIsMounted();
  const location = useLocation();
  const site = (data || {}).sanitySiteSettings;
  const windowQuery =
    mounted && typeof window !== "undefined"
      ? new URLSearchParams(window.location.search).get("s")
      : undefined;
  const [searchQuery, setSearchQuery] = useLocalStorage(
    "currentSearch",
    windowQuery || ""
  );
  const [episodeResultsList, setEpisodeResultsList] = useState<
    Array<TransistorEpisodeInterface>
  >([]);
  const [postResultsList, setPostResultsList] = useState<
    Array<BlogPostInterface>
  >([]);
  const [productResultsList, setProductResultsList] = useState<
    Array<ProductInterface>
  >([]);
  const { index: episodeIndex, store: episodeStore } = data.localSearchEpisodes;
  const { index: postsIndex, store: postsStore } = data.localSearchPosts;
  const {
    index: productsIndex,
    store: productsStore
  } = data.localSearchProducts;
  const episodeResults: Array<EpisodeResult> = useFlexSearch(
    searchQuery,
    episodeIndex,
    episodeStore
  );
  const postResults: Array<PostResult> = useFlexSearch(
    searchQuery,
    postsIndex,
    postsStore
  );
  const productResults: Array<ProductResult> = useFlexSearch(
    searchQuery,
    productsIndex,
    productsStore
  );

  useEffect(() => {
    const allPosts = data.allSanityPost.nodes;
    const allEpisodes = data.allTransistorEpisode.nodes;
    const allProducts = data.allSanityProduct.nodes;
    setEpisodeResultsList(unflattenEpisodeResults(episodeResults, allEpisodes));
    setPostResultsList(unflattenPostResults(postResults, allPosts));
    setProductResultsList(unflattenProductResults(productResults, allProducts));
  }, [episodeResults]);

  useEffect(() => {
    if (searchQuery && searchQuery.length > 2) {
      debounce(
        () => trackSearchQuery(episodeResultsList.length > 0, searchQuery),
        300
      );
    }
  }, [searchQuery, episodeResultsList.length]);

  const handleBack = (e: React.MouseEvent) => {
    e.preventDefault();
    if (
      mounted &&
      typeof window !== "undefined" &&
      location.pathname === "/search"
    ) {
      window.history.back();
      if (searchQuery.length) {
        setTimeout(() => {
          setSearchQuery("");
        }, 200);
      }
    }
  };

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <>
      <SEO
        title={`Search | ${site.title}`}
        description={site.description}
        keywords={site.keywords}
      />
      <Page title="Search" layout="vertical">
        <div css={styles.closeIconContainer}>
          <FontAwesomeIcon icon={faTimes} onClick={handleBack} />
        </div>
        <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
        <SearchResults
          episodesList={episodeResultsList}
          postsList={postResultsList}
          queryLength={searchQuery.length}
          productsList={productResultsList}
        />
      </Page>
    </>
  );
};

export default SearchPage;
