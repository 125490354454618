import { CSSObject } from "@emotion/core";
import { EmotionStyles } from "../../../interfaces/emotion";
import { breakpointMixins } from "../../../styles/breakpoints";
import { colors } from "../../../styles/colors";
import { variables } from "../../../styles/variables";

const { spacing } = variables;

const blogLayoutBase: CSSObject = {
  [breakpointMixins.md]: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: `${spacing.raw.large * 2}rem`,
    rowGap: `${spacing.raw.large * 2}rem`
  },
  [breakpointMixins.lg]: {
    gridTemplateColumns: "1fr 1fr 1fr"
  }
};

export const styles: EmotionStyles = {
  blogList: {
    listStyleType: "none",
    padding: 0
  },
  blogList_blog: { ...blogLayoutBase, paddingBottom: spacing.large },
  blogList_vertical: blogLayoutBase,
  blogList_horizontal: {
    display: "blog"
  },
  blogPostPreviewItem: {
    marginBottom: spacing.large,
    width: "100%",
    [breakpointMixins.md]: {
      marginBottom: 0
    }
  },
  blogPost_horizontal: {
    marginBottom: spacing.large,
    paddingBottom: spacing.medium,
    borderBottom: `1px solid ${colors.lightGrey}`,
    [breakpointMixins.md]: {
      paddingBottom: spacing.large,
      marginBottom: spacing.large
    }
  },
  blogPostLast: {
    borderBottomWidth: 0
  },
  blogHeading_horizontal: {
    marginBottom: spacing.large
  },
  blogPostTitleLink: {
    textDecoration: "none"
  }
};
