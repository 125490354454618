import React, { Dispatch, SetStateAction, useEffect, useRef } from "react";
import useIsMountedRef from "use-is-mounted-ref";
import { searchBarStyles as styles } from "./styles";

interface SearchBarProps {
  searchQuery: string;
  setSearchQuery: Dispatch<SetStateAction<string>>;
}

const SearchBar: React.FC<SearchBarProps> = ({
  searchQuery,
  setSearchQuery
}) => {
  const isMountedRef = useIsMountedRef();
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isMountedRef.current && inputRef.current) {
      inputRef.current.focus();
    }
  });

  return (
    <form action="/" method="get" autoComplete="off" css={styles.form}>
      <label htmlFor="header-search">
        <span css={styles.label}>Search episodes and posts</span>
      </label>
      <input
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        type="text"
        id="header-search"
        placeholder="Search the content..."
        name="s"
        css={styles.input}
        ref={inputRef}
      />
    </form>
  );
};

export default SearchBar;
