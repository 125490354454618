import React, { Fragment } from "react";
import { Flipper } from "react-flip-toolkit";
import { BlogPostInterface } from "../../interfaces/blogPost";
import { TransistorEpisodeInterface } from "../../interfaces/podcasts";
import BlogPostPreviewList from "../BlogPostPreviewList";
import EpisodeList from "../EpisodeList";
import Heading from "../Heading";
import { searchResultsStyles as styles } from "./styles";
import { ProductInterface } from "../../interfaces/product";
import ProductPreviewList from "../ProductPreviewList";
import { ProductFilterContextProvider } from "../../contexts/ProductFilterContext";
import setTestId from "../../helpers/setTestId";
export interface SearchResultsProps {
  queryLength: number;
  episodesList: Array<TransistorEpisodeInterface>;
  postsList: Array<BlogPostInterface>;
  productsList: Array<ProductInterface>;
}

interface ResultCountProps {
  count: number;
}

const ResultCount: React.FC<ResultCountProps> = ({ count }) => (
  <div css={styles.resultCount} {...setTestId("results-count")}>
    There {count === 1 ? "is" : "are"} {count} result{count === 1 ? "" : "s"}
  </div>
);

const SearchResults: React.FC<SearchResultsProps> = ({
  queryLength,
  episodesList,
  postsList,
  productsList
}) => {
  const totalResults =
    episodesList.length + postsList.length + productsList.length;
  if (queryLength < 2) return null;

  if (totalResults === 0 && queryLength >= 2) {
    return (
      <div css={styles.noResultsCard}>
        Sorry, there was no content matching your search. Please try something
        else
      </div>
    );
  }
  return (
    <Fragment>
      <ResultCount count={totalResults} />
      <Flipper
        flipKey={[
          ...postsList.map(p => p.id).join(""),
          ...episodesList.map(ep => ep.id)
        ].join("")}
      >
        {productsList.length > 0 && (
          <ProductFilterContextProvider
            products={productsList}
            brands={[]}
            categories={[]}
            enabledFilters={["categories", "brands"]}
          >
            <ProductPreviewList
              collapsePadding={true}
              headingLevel="h3"
              title="Products"
              enableFilters={false}
              layout="horizontal"
            />
          </ProductFilterContextProvider>
        )}
        {episodesList.length > 0 && (
          <Fragment>
            <Heading headingLevel="h3" styles={styles.heading}>
              Podcast Episodes
            </Heading>
            <EpisodeList
              episodes={episodesList}
              collapsePadding={true}
              hideTabs={true}
            />
          </Fragment>
        )}
        {postsList.length > 0 && (
          <BlogPostPreviewList
            nodes={postsList.map(post => ({ node: post }))}
            layout="horizontal"
            headingLevel="h3"
            title="Blog Posts"
            collapsePadding={true}
          />
        )}
      </Flipper>
    </Fragment>
  );
};

export default SearchResults;
