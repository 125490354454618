import { variables } from "../../styles/variables";
import { EmotionStyles } from "../../interfaces/emotion";
import { baseColors } from "../../styles/colors";

const { spacing, maxWidth } = variables;

export const searchBarStyles: EmotionStyles = {
  form: {
    display: "flex"
  },
  label: {
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    whiteSpace: "nowrap",
    width: 1
  },
  input: {
    flexGrow: 1,
    padding: `${spacing.medium} ${spacing.small}`
  }
};

export const searchResultsStyles: EmotionStyles = {
  noResultsCard: {
    maxWidth,
    margin: `0 ${spacing.medium}`
  },
  resultCount: {
    textAlign: "right",
    margin: `0 ${spacing.medium}`,
    color: baseColors.grey,
    fontSize: "0.875rem"
  },
  heading: {
    marginTop: spacing.large
  }
};
