import React from "react";
import { BlogPostPreviewListProps } from "./props";
import { styles } from "./styles";
import CTASection from "../CTASection";
import Heading from "../Heading";
import Inner from "../Inner";
import { BlogPostInterface } from "../../interfaces/blogPost";
import { Link } from "gatsby";

const renderNodeContent = (node: BlogPostInterface) => {
  if (node._rawExcerpt && node._rawExcerpt[0]) {
    return [node._rawExcerpt[0]];
  }
  if (node._rawBody && node._rawBody[0]) {
    return [node._rawBody[0]];
  }
};

const BlogPostPreviewList: React.FC<BlogPostPreviewListProps> = ({
  nodes,
  title,
  layout = "blog",
  headingLevel = "h1",
  collapsePadding
}) => {
  return (
    <Inner collapseAll={collapsePadding}>
      {title && (
        <Heading
          headingLevel={headingLevel}
          styles={
            layout === "horizontal" ? styles.blogHeading_horizontal : undefined
          }
        >
          {title}
        </Heading>
      )}
      {nodes && nodes.length ? (
        <ul css={[styles.blogList, styles[`blogList_${layout}`]]}>
          {nodes &&
            nodes.map(({ node }, i) => (
              <li
                key={node.id}
                css={[
                  styles.blogPostPreviewItem,
                  layout === "horizontal" && styles.blogPost_horizontal,
                  i === nodes.length - 1 &&
                    layout === "horizontal" &&
                    styles.blogPostLast
                ]}
              >
                <CTASection
                  layout={layout}
                  theme="light"
                  title={
                    <Link
                      to={`/blog/${node.slug.current}`}
                      css={styles.blogPostTitleLink}
                    >
                      {node.title}
                    </Link>
                  }
                  content={renderNodeContent(node)}
                  cta={{
                    href: `/blog/${node.slug.current}`,
                    content: "Read more here"
                  }}
                  image={node.mainImage}
                  collapseAllPadding={true}
                />
              </li>
            ))}
        </ul>
      ) : null}
    </Inner>
  );
};

export default BlogPostPreviewList;
